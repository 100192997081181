import './App.css';
import Routes from "./Routes";

import logo from "./images/robo.jpg";

function App() {
  return (
    <div className="App m-4 mt-12 content-center ">
      <div className="m-4 mx-auto bg-gray-100 p-4 max-w-4xl rounded-md">
        <div className="pb-4 pt-2 flex content-center justify-center -top-12 relative">
          <img src={logo} className="border-2 rounded-full border-gray-400" alt="rjyoung.ca" />
        </div>

        <Routes />
      </div>
    </div>
  );
}

export default App;
