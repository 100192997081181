import React from "react";
import "./Home.css";
import SocialLinks from "../components/SocialLinks";
import PhotoSet from "../components/PhotoSet";

export default function Home() {
  const [homeState, setHomeState] = React.useState({
    tries: 0,
    photoState: [],
  });

  function attemptRender() {
    if (homeState.photoState.length === 0 && homeState.tries < 4) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            "https://q9stf289dk.execute-api.us-east-1.amazonaws.com/dev/photostream"
          );

          // Check if the response is ok
          if (!response.ok) {
            setHomeState({ tries: homeState.tries + 1, photoState: [] });
          }

          // Convert response to json
          const p = await response.json();

          setHomeState({ tries: homeState.tries + 1, photoState: p.photos });
        } catch (err) {
          console.log(err);
          setHomeState({ tries: homeState.tries + 1, photoState: [] });
          setTimeout(attemptRender, 200);
        }
      };
      fetchData();
    }
  }

  React.useEffect(() => {
    attemptRender();
  });

  return (
    <div className="Home">
      <div>
        <p>Hey you made it here, cheers! Are you looking for something?</p>

        <p>How about some links?</p>
        <SocialLinks />

        <PhotoSet photos={homeState.photoState} />
      </div>
    </div>
  );
}
