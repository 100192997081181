
import React from "react";
import flickrlogo from "./flickr-logo.png";
import spotify from "./spotify.png";
import linkedinlogo from "./linkedin.svg";
import instagram from "./instagram.svg";
import expotools from "./ev-tools.svg";
import notepad from "./notepad.svg";

import './SocialLinks.css';

export default function SocialLinks() {
  return (
    <div className="SocialLinks">
      <div className="flex pt-4 pb-4">

        <a className="p-1" href="https://www.flickr.com/photos/bobbotron/">
          <img className="social-icon rounded-lg" alt="Flickr" src={flickrlogo} />
        </a> 
        <a className="p-1" href="https://www.linkedin.com/in/rob-young-6881b31/">
          <img className="social-icon" alt="Linkedin" src={linkedinlogo} />
        </a>
        <a className="p-1" href="https://www.instagram.com/robrobbob123/">
          <img className="social-icon" alt="Instagram" src={instagram} />
        </a>
        <a className="p-1" href="https://open.spotify.com/playlist/5m4kUvG7KlaeVVXhlZVAi0?si=508c002f46cc444c">
          <img className="social-icon" alt="Spotify" src={spotify} />
        </a>
        <a className="p-1" href="https://exposure-buddy.rjyoung.ca/">
          <img className="social-icon" alt="EV Tools" src={expotools} />
        </a>
        <a className="p-1" href="https://notepad.rjyoung.ca/">
          <img className="social-icon" alt="Notepad" src={notepad} />
        </a>
      </div>
    </div>
  );
}
