import React from "react";
import './Home.css';
import GoogleLogin from 'react-google-login';



export default function Home() {
    const responseGoogle = (response) => {
        console.log(response);
      }  
  
  return (<GoogleLogin
    clientId="749105778017-k9qt6dldf68vpici9q5uptvbtva6qsdf.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />);
}