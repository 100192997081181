import React from "react";

export default function Tos() {
    return (
        <>
            <p className="pb-4">Terms of Service</p>

            <p>This site is intended to be informal and informational.  Any services provided are provided as is.  Any users abusing the system, using it as a platform for harrasment or other nefarious intent will be removed.</p>
        </>
    );
}
