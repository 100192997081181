import Photo from './Photo';

export default function PhotoSet({ photos }) {
    return (
        <>
        {photos.length > 0 &&
            <>
                <p className="pb-2">Here are some of my photos.</p>
                <div className="grid auto-rows-max md:grid-cols-5 xl:grid-cols-6 grid-cols-3 content-center justify-items-center gap-4">
                    {photos.map(p => (
                        <Photo key={p.image} photo={p} />
                    ))}
                </div>
            </>
        }
        </>
    );
};